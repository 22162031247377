import React from "react"

import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  List,
  ListItem,
  Span,
  VideoPlayer,
} from "@/components"

export default function Applied() {
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO title="Call Scheduled" description="Call Scheduled" noindex={true} />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box width={["100%", null, null, 5 / 12]} textAlign="center" mx="auto">
          <Text variant="subhead" mt="4rem">
            Step 1
          </Text>

          <Heading
            level="1"
            mb="1rem"
            mt="1rem"
            px={["2rem", "2rem", "2rem", "2rem"]}
          >
            Call Scheduled
          </Heading>
          <Text mb="1rem" mt="1rem" px={["2rem", "2rem", "2rem", "2rem"]}>
             Watch this video below for important information regarding your
            call
          </Text>
        </Box>
        <Width
          display="flex"
          flexDirection={["column-reverse", "row"]}
          pt={["0rem", null, null, "0rem"]}
          pb={["0", null, null, "1.9rem"]}
        >
          <Box
            width={["100%", 12 / 12]}
            textAlign="left"
            ml={["0", "auto"]}
            pb={["0rem", null, null, "0rem"]}
          >
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              p="0.6rem"
              css={{ cursor: "pointer" }}
            >
              <VideoPlayer
                width="auto"
                maxWidth="auto"
                url="https://vimeo.com/986864754/fc52f84dcc"
              />
            </Box>
          </Box>
        </Width>
        {/* <Width
          display="flex"
          flexDirection={["column-reverse", "row"]}
          pt={["0rem", null, null, "0rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", 12 / 12]}
            textAlign="left"
            ml={["0", "auto"]}
            pb={["0rem", null, null, "0rem"]}
          >
            <Box
              width={["100%", null, null, 8 / 12]}
              textAlign="center"
              mx="auto"
            >
              <Text variant="subhead" mt="4rem">
                Step 2
              </Text>
              <Text mb="1rem" mt="1rem" px={["2rem", "2rem", "2rem", "2rem"]}>
                After watching the above video, Watch the below video before
                your call and to learn more about what fretboard fluency is, how to get there and why it's the #1 skill to break the intermediate plateau on guitar.
              </Text>
            </Box>
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              p="0.6rem"
              css={{ cursor: "pointer" }}
            >
              <VideoPlayer
                width="auto"
                maxWidth="auto"
                url="https://vimeo.com/781918437/ea9cedce4b"
              />
            </Box>
          </Box>
        </Width> */}
      </Box>
    </Layout>
  )
}
